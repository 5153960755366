/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors */

/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { MDXProvider } from '@mdx-js/react';
import { useLocation } from '@reach/router';

import {
	DoDont,
	type DoDontProps,
	Example,
	gutterStyles,
	hideOnMobileNavigationStyles,
	NAVIGATION_BREAKPOINTS,
} from '@af/design-system-docs-ui';
import AppProvider from '@atlaskit/app-provider';
import { Code as Snippet } from '@atlaskit/code';
import {
	Content,
	LeftSidebarWithoutResize,
	Main,
	PageLayout,
	TopNavigation,
	useCustomSkipLink,
} from '@atlaskit/page-layout';
import { media } from '@atlaskit/primitives/responsive';
import { N20, N800 } from '@atlaskit/theme/colors';
import { gridSize } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

import AppProviderRouterLink from '../../../components/app-provider-router-link';
import Footer from '../../../components/footer';
import GlobalStyles from '../../../components/global-styles';
import SearchModal from '../../../components/search';
import SideNavigation from '../../../components/side-navigation';
import TopNav from '../../../components/top-nav';
import useSideNavigation from '../../../hooks/use-side-navigation';
import PropsLogger from '../utilities/props-logger';

import MetaTags from './meta-tags';
import Props from './props';

type LayoutProps = {
	children: any;
	hasNoSidebar?: boolean;
};

const innerContentStyles = css({
	height: '100%',
	position: 'relative',
	gridArea: 'main',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[NAVIGATION_BREAKPOINTS.MOBILE]: {
		isolation: 'isolate',
	},
	[media.above.sm]: {
		display: 'grid',
		gridTemplateAreas: `
      'hero hero'
      'content nav'
      'footer footer'
    `,
		gridTemplateColumns: 'minmax(25rem, 60rem) 1fr',
		gridTemplateRows: 'auto 1fr',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Ignored via go/DSP-18766
export const mainContentStyles = [
	gutterStyles,
	css({
		display: 'grid',
		/* We absolutely position child tabs. */
		position: 'relative',
		gap: token('space.200', '16px'),
		gridAutoRows: 'max-content',
		gridTemplateColumns: 'repeat(12, 1fr)',
		gridArea: 'content',
		marginBlockStart: token('space.0', '0px'),
		paddingBlockEnd: token('space.500', '40px'),
		paddingBlockStart: token('space.400', '32px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'> *': {
			gridColumn: '1 / span 12',
			marginBlockEnd: token('space.0', '0px'),
			marginBlockStart: token('space.0', '0px'),
		},
		'.brisk-table': {
			thead: {
				tr: {
					/* Hack to work around pretty prop types heading not being bold atm. */
					fontWeight: token('font.weight.medium', '500'),
				},
			},
			td: {
				verticalAlign: 'top',
			},
			'td > code': {
				padding: token('space.050', '4px'),
				backgroundColor: token('color.background.neutral', N20),
				borderRadius: token('border.radius', '3px'),
				color: token('color.text', N800),
			},
			'td > div': {
				/* Hack to remove margin from ERT div. */
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
				margin: `${token('space.0', '0px')} !important`,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
			'td:not(:last-child)': {
				paddingInlineEnd: token('space.100', '8px'),
			},
		},
	}),
];

// total width is 304
const sidebarWidth = gridSize() * 38;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Ignored via go/DSP-18766
export const spanAllContentStyles = css({
	alignSelf: 'center',
	gridArea: 'content / content / nav / nav',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const spanAllHorizontalContentStyles = css({
	gridColumn: 'content / nav',
});

const DoDontWrapper = ({ fullWidth, ...other }: DoDontProps & { fullWidth?: boolean }) => {
	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'grid', gridColumn: `span ${fullWidth ? '12' : '6'}` }}
		>
			<DoDont {...other} />
		</div>
	);
};

const SIDE_NAVIGATION_ID = 'side-navigation';
const FOOTER_ID = 'footer';

const shortcodes = {
	DoDont: DoDontWrapper,
	Example,
	PropsLogger,
	PropsTable: Props,
	Snippet,
} as const;

const RegisterSideNavSkipLink = () => {
	useCustomSkipLink(SIDE_NAVIGATION_ID, 'Side navigation', 2);
	return null;
};
const RegisterFooterSkipLink = () => {
	useCustomSkipLink(FOOTER_ID, 'Footer', 5);
	return null;
};

const defaultThemeSettings = {
	light: 'light',
	dark: 'dark',
	spacing: 'spacing',
	typography: 'typography-adg3',
	shape: 'shape',
} as const;

/**
 * __Layout__
 *
 * A layout for design system docs.
 *
 */
const Layout = ({ hasNoSidebar, children }: LayoutProps) => {
	const navigation = useSideNavigation();
	const { pathname } = useLocation();

	const isCustomThemeAdded = useRef(false);

	useEffect(() => {
		if (!pathname.startsWith('/components') || isCustomThemeAdded.current) {
			return;
		}
		// A temporary workaround to make sub theme working on examples
		// to support custom attribute on DOMs [data-custom-mode][data-color-mode]
		const darkStyle = document.querySelector('style[data-theme="dark"]');
		const lightStyle = document.querySelector('style[data-theme="light"]');

		if (darkStyle?.textContent) {
			darkStyle.textContent = darkStyle.textContent.replace(
				'\nhtml[data-color-mode="light"][data-theme~="light:dark"],\nhtml[data-color-mode="dark"][data-theme~="dark:dark"]',
				'\nhtml[data-color-mode="light"][data-theme~="light:dark"],\nhtml[data-color-mode="dark"][data-theme~="dark:dark"], html [data-custom-mode="true"][data-color-mode="dark"]',
			);
		}
		if (lightStyle?.textContent) {
			lightStyle.textContent = lightStyle.textContent.replace(
				'\nhtml[data-color-mode="light"][data-theme~="light:light"],\nhtml[data-color-mode="dark"][data-theme~="dark:light"]',
				'\nhtml[data-color-mode="light"][data-theme~="light:light"], html [data-custom-mode="true"][data-color-mode="light"],\nhtml[data-color-mode="dark"][data-theme~="dark:light"]',
			);
		}
		isCustomThemeAdded.current = true;
	}, [pathname]);

	const showSidebar =
		!hasNoSidebar &&
		// Don't show sidebar on home page
		pathname !== '/';

	const [isSearchOpen, setIsSearchOpen] = useState(false);

	const handleSearchClose = useCallback(() => {
		setIsSearchOpen(false);
	}, []);
	const handleSearchOpen = useCallback(() => {
		setIsSearchOpen(true);
	}, []);

	return (
		<MDXProvider components={shortcodes}>
			<GlobalStyles />
			<MetaTags isGlobal />
			<AppProvider
				defaultTheme={defaultThemeSettings}
				defaultColorMode="light"
				routerLinkComponent={AppProviderRouterLink}
			>
				<PageLayout>
					<TopNavigation id="nav" skipLinkTitle="Site navigation">
						<TopNav navigation={navigation} onSearchOpen={handleSearchOpen} />
					</TopNavigation>
					<Content>
						{showSidebar && (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							<div css={hideOnMobileNavigationStyles}>
								<RegisterSideNavSkipLink />
								<LeftSidebarWithoutResize id="layout_left-sidebar" width={sidebarWidth} isFixed>
									<SideNavigation
										device="desktop"
										navigation={navigation}
										id={SIDE_NAVIGATION_ID}
									/>
								</LeftSidebarWithoutResize>
							</div>
						)}
						<Main id="main" skipLinkTitle="Main content">
							<div css={innerContentStyles}>
								{children}
								<Footer id={FOOTER_ID} />
								<RegisterFooterSkipLink />
							</div>
						</Main>
					</Content>
				</PageLayout>
				<SearchModal isOpen={isSearchOpen} onClose={handleSearchClose} />
			</AppProvider>
		</MDXProvider>
	);
};

export default Layout;
